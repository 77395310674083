import { Navigate, useRoutes } from 'react-router-dom';
// auth
// layouts
import CompactLayout from '../layouts/compact';
// config
//
import {
  ChatPage,
  Page500,
  Page403,
  Page404,
} from './elements';
import GuestGuard from '../auth/GuestGuard';
import LoginPage from '../pages/auth/LoginPage';
import AuthGuard from '../auth/AuthGuard';
import RegisterPage from '../pages/auth/RegisterPage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      children: [
        {
          index: true,
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
        {
          path: 'dashboard',
          element: (
            <AuthGuard>
              <ChatPage />
            </AuthGuard>
          )
        },
      ],
    },

    {
      element: <CompactLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
