import axios from 'axios';
// config

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: "https://auth-ai.arminek.xyz" });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

const axiosAIRunnerInstance = axios.create({ baseURL: 'https://runtime4ai.arminek.xyz' });
axiosAIRunnerInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

const githubAPI = axios.create({ baseURL: 'https://api.github.com' });
githubAPI.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export { axiosAIRunnerInstance, githubAPI };
